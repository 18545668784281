<template>
	<div
		class="MainRight_1 eventWraper flex direction_column justify_content_c align_items_c"
		v-if="userInfo.id != null && userInfo.id > 0"
	>
		<div class="editorBox flex justify_content_sb">
			<div
				class="MainRight_1_Img flex align_items_c pointer"
				@click="$router.push({ path: '/home_wz', query: { id: userInfo.id } })"
			>
				<img :v-lazy="userInfo.avatar_img" :key="userInfo.avatar_img" alt="" />
				<div class="MainRight_1_Img_r">
					<div class="editorName">{{ userInfo.nickname }}</div>
					<div class="typeInfo">
						<span class="typeName"
							>{{ userInfo.type == 3 ? "企业专栏 " : "个人专栏 " }}
						</span>
						<span class="MainRight_1_look">
							| {{ userInfo.activity_num }} 文章
						</span>
					</div>
				</div>
			</div>
			<div
				class="gzBtn flex align_items_c justify_content_c pointer"
				v-if="userInfo.is_follow == 0"
				@click="followAuthorFun(userInfo.id, 1, author_id)"
				>+关注</div
			>
			<div
				class="gzBtn flex align_items_c justify_content_c pointer"
				v-if="userInfo.is_follow == 1"
				@click="followAuthorFun(userInfo.id, 0, author_id)"
				>已关注</div
			>
		</div>
		<div class="MainRight_1_list">
			<div
				class="MainRight_1_item pointer"
				v-for="(item, index) in UserInformation"
				:key="index"
				@click="toPage(item)"
			>
				<p class="articleTitle ellipsis_multiple hovercolor">{{
					item.title
				}}</p>
				<p class="articleTime">
					<img src="https://zy.metaera.media/assets/images/home/2.png" alt="" />
					<span v-time="item.release_time"></span>
				</p>
			</div>
		</div>
		<div
			class="readMore pointer"
			@click="$router.push({ path: '/home_wz', query: { id: userInfo.id } })"
		>
			阅读更多
		</div>
	</div>
</template>

<script>
import { myMixin } from "@/utils/mixin.js";
export default {
	mixins: [myMixin],
	props: ["userInfo", "UserInformation", "author_id"],
	methods: {
		toPage(item) {
			this.$router.push({
				path: "/gw_detail",
				query: { id: item.id },
			});
			location.reload();
		},
		gettoUserInfo(id) {
			this.$emit("gettoUserInfo", id);
		},
	},
};
</script>

<style scoped>
.MainRight_1 {
	box-sizing: border-box;
	width: 372px;
	padding: 0 22px 20px;
	background: #fff;
	opacity: 1;
	border: 1px solid #dcdcdc;
}
.MainRight_1_Img img {
	width: 49px;
}
.MainRight_1_Img_r {
	margin-left: 11px;
}
.editorBox {
	width: 372px;
	box-sizing: border-box;
	padding: 16px 22px 15px;
	background: linear-gradient(
		180deg,
		#e5ecff 0%,
		rgba(226, 235, 255, 0.29) 100%
	);
	border-radius: 8px 8px 0 0;
	align-items: center;
}
.editorName {
	line-height: 22px;
	font-size: 16px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #000;
}
.typeInfo {
	line-height: 17px;
}
.typeName {
	line-height: 17px;
	font-size: 12px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	color: #000;
}
.MainRight_1_look {
	line-height: 17px;
	font-size: 12px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	color: #9999a6;
}

.gzBtn {
	width: 70px;
	height: 34px;
	background: #fff;
	border-radius: 17px;
	opacity: 1;
	border: 1px solid #0056ff;
	font-size: 15px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 400;
	color: #0056ff;
}

.MainRight_1_list {
	width: 306px;
}

.MainRight_1_item {
	width: 100%;
	padding: 18px 0;
	border-bottom: 1px solid #efeff4;
}
.MainRight_1_item:last-child {
	border-bottom: 0;
}
.articleTitle {
	font-size: 14px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #333;
	line-height: 21px;
	overflow: hidden;
}
.articleTime {
	display: flex;
	align-items: center;
	margin-top: 10px;
}
.articleTime img {
	width: 10px;
	margin-right: 6px;
}
.articleTime span {
	font-size: 12px;
	font-family: DINPro-Medium, DINPro;
	font-weight: 500;
	color: #a0a0a9;
	line-height: 17px;
}
.readMore {
	width: 210px;
	height: 40px;
	line-height: 40px;
	background: linear-gradient(
		45deg,
		#9945ff,
		#8752f3,
		#5497d5,
		#43b4ca,
		#28e0b9,
		#19fb9b
	);
	border-radius: 6px;
	border: 1px solid #eef0f8;
	text-align: center;
	color: #fff;
	font-size: 13px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
}
</style>
